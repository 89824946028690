import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  HookComponentProvider,
  ContactForm,
  LocationSelect,
  LocationsContext,
} from "@bluefin/components";
import { useContext } from "react";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Background columns={1} stackable={true} className={"contact-hero"}>
            <Background.Image
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              forceChildren={true}
            >
              <div className={"contact-form-container"}>
                <HookComponentProvider
                  hook={useContext}
                  component={
                    <ContactForm
                      buttonText={"Submit"}
                      centeredHeaders={false}
                      hideOptIn={false}
                      className={""}
                      fluidButton={true}
                      header={
                        <Grid
                          columns={2}
                          stackable={true}
                          className={"contact-form-header"}
                        >
                          <Grid.Column>
                            <Header as={"div"} className={"h1"}>
                              Contact
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <LocationSelect useContext={true} search={false} />
                          </Grid.Column>
                        </Grid>
                      }
                      inline={false}
                      showLabels={true}
                      subheader={""}
                      termsCopy={""}
                      withContainer={false}
                      businessId={fishermanBusiness._id}
                      businessType={fishermanBusiness.type}
                    />
                  }
                  hookToPropsMap={{ locationId: "activeLocation.id" }}
                  hookParams={[LocationsContext]}
                />
              </div>
            </Background.Image>
          </Background>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
